import React, {
  useState,
  useEffect
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  CircularProgress,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Slide,
  Menu,
  MenuItem,
  FormControl,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';
import {
  TransitionProps
} from '@material-ui/core/transitions';
import {
  Skeleton
} from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'

import localization from '../../utils/localizations';
import {
  Header
} from '../../views/panels/Header';

import {
  SessionContext
} from '../../data/Session';

import {
  BaseAPI,
} from '../../data/BaseAPI';

import { useProfile } from '../../data/Profile';

import {
  Order,
  User,
  DealerPermission
} from '../../types';

import OrderList from '../../views/collections/OrderList';
import DateTimeView from '../../views/singles/DateTimeView';
import OrderDetail from '../../views/singles/OrderDetail';
import OrderCollection from '../../views/collections/OrderCollection';
import { downloadFile } from '../../utils/downloadFile';
import { levenshteinDistance } from '../../utils/formUtils';
import moment from 'moment';

const appConfig = (window as any).APP_CONFIG;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
  },
  appBar: {
    position: 'relative',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10
  },
  marginVertical: {
    marginTop: 20,
    marginBottom: 20,
  },

  uploadContainer: {
    padding: 10,
    marginBottom: 20,
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    marginBottom: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  marginBottom: {
    marginBottom: 16,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
  },
  formButtons: {
    display: "flex",
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
  },
  fieldContainer: {
    marginBottom: 20,
  },
  link: {
    color: '#115293'
  },
  table: {

  }
}));


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function LoadingView() {
  return (
    <div style={{position: 'relative', padding: 10}}>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
      <Skeleton variant="rect" width={'100%'} height={20} style={{marginBottom: 10}}/>
    </div>
  );
}


export default function FBInvoiceToNPCSVPage() {
  const classes = useStyles();
  
  const [open, setOpen] = useState(false);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [navigateBack, setNavigateBack] = useState(false);

  const [showEditOrderForm, setShowEditOrderForm] = useState(false);
  const [editOrder, setEditOrder] = useState<Order|null>(null);
  const [editOrderCounter, setEditOrderCounter] = useState(0);

  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] = useState<HTMLElement|null>(null);


  const { path, url } = useRouteMatch();
  const backUrl = `/`;

  useEffect(() => {
    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    },300);

  }, []);

  const goBack = () => {
    setOpen(false);
    setTimeout(() => {
      setNavigateBack(true);
    }, 300);
  };

  const canAccessBoatOrderingPortal = (permissions?: DealerPermission[], role?: string) => {
    let hasPermission = false;
    if (role === 'admin') hasPermission = true;
    if (permissions) {
      permissions.forEach(permission => {
        if (permission.admin || permission.order) hasPermission = true;
      });
    }
    return hasPermission;
  }

  if (!canAccessBoatOrderingPortal(profile.permissions, profile.role)) {
    return (<>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={goBack}
      >
        <Typography variant="h2" style={{marginLeft: 14, marginTop: 14}}>Loading...</Typography>
        <div style={{height: 60}}></div>
      </Drawer>
      {navigateBack && <Redirect to={backUrl} />}
    </>);
  }

  return (
    <SessionContext.Consumer>
      {({session}) => (
        <>
          <Header
            session={session}
            title={`Tools`}
            breadcrumbs={[
              {
                title: "Orders",
                link: `${appConfig.homepage}orders/`
              }, {
                title: "Transform FishBowl Invoice CSV",
                link: `${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`
              }
            ]}
          />
          <div className={classes.root}>
            <Paper className={classes.toolbar}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  component={RouterLink}
                  to={`${appConfig.homepage}order-form/`}
                >New Order</Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/`}
                  style={{marginLeft: 12}}
                >Orders</Button>
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`${appConfig.homepage}orders/quotes/`}
                  style={{marginLeft: 12}}
                >Quotes</Button>
                <Button
                  variant="contained"
                  aria-controls="order-tools-menu"
                  aria-haspopup="true"
                  onClick={(e) => {
                    setToolsMenuAnchorEl(e.currentTarget)
                  }}
                  endIcon={<ExpandMoreIcon />}
                  style={{float: 'right'}}
                >
                  Tools
                </Button>
                <Menu
                  id="order-tools-menu"
                  anchorEl={toolsMenuAnchorEl}
                  keepMounted
                  open={Boolean(toolsMenuAnchorEl)}
                  onClose={() => setToolsMenuAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setToolsMenuAnchorEl(null);
                    }}
                    component={RouterLink}
                    to={`${appConfig.homepage}orders/tools/fb-invoice-to-np-csv/`}
                    >Transform FishBowl Invoce into NorthPoint CSV</MenuItem>
                </Menu>
              </div>
            </Paper>

            <Paper style={{padding: 8}}>
              <InvoiceTransformerView />
            </Paper>
          </div>

        </>
      )}
    </SessionContext.Consumer>
  );
}


function InvoiceTransformerView() {
  const classes = useStyles();
  const [showCSVSelector, setShowCSVSelector] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState<any|null>();
  const [csvFields, setCSVFields] = useState<string[]>([]);
  const [csvData, setCSVData] = useState<any|null>(null);
  const [csvDealerName, setCSVDealerName] = useState('');
  const [crossReferenceList, setCrossReferenceList] = useState<(string[])[]>([]);
  const [showCrossReferenceTable, setShowCrossReferenceTable] = useState(false);
  const [matchedDealerName, setMatchedDealerName] = useState('');


  const uploadCSVFile = async (file: any) => {
    let success = false;
    setIsLoading(true);
    setErrorMessage('');
    let formData = new FormData();
    formData.append('csv_file', file);

    const api = new BaseAPI();
    api.path = 'tools/'
    try {
      const [result, response, error] = await api.post(formData, 'transform-fb-invoice-to-northpoint-csv/');
      console.log(result)
      if (result && result.success) {

        if (result.dealer_name && result.cross_reference_list) {
          let score = 1;
          let plantId = '';
          let slocId = '';
          let dealerNumber = '';
          let matchedDealerName = '';
          let csvDealerName = result.dealer_name.trim().toLowerCase();
          
          (result.cross_reference_list as (string[])[]).filter((row, i) => i > 0).forEach(row => {
            let dealerName = row[8].trim();

            if (!dealerName) return;
            if (dealerName.toLowerCase() === csvDealerName) {
              score = 0;
              dealerNumber = row[7];
              slocId = row[3];
              plantId = row[5];
              matchedDealerName = dealerName;
              return;
            }
            let distance = levenshteinDistance(dealerName.toLowerCase(), csvDealerName)
            let currentScore = distance / dealerName.length;
            if (currentScore < score) {
              dealerNumber = row[7];
              slocId = row[3];
              plantId = row[5];
              matchedDealerName = dealerName;
              score = currentScore;
              console.log(currentScore, dealerName)
            }
          });

          console.log(score, matchedDealerName)
          result.data['SLOC ID'] = slocId;
          result.data['Plant ID'] = plantId;
          result.data['Dealer Number'] = dealerNumber;
          setMatchedDealerName(matchedDealerName);
        }
        setCSVData(result.data);
        setCSVFields(result.fields);
        setCrossReferenceList(result.cross_reference_list);
        setCSVDealerName(result.dealer_name);
        setShowCSVSelector(false);
        setSelectedFile(null);

      }
      if (result && !result.success && result.message) {
        setErrorMessage(result.message);
      }

    } catch (error) {
      console.log(error)
      setErrorMessage(`${error}`)
    }
    setIsLoading(false);
  };


  const generateNorthPointCSV = async () => {
    let success = false;
    setIsLoading(true);
    setErrorMessage('');

    const api = new BaseAPI();
    api.path = 'tools/'
    try {
      const [result, response, error] = await api.post({data: csvData}, 'generate-northpoint-csv/');
      console.log(result)
      if (result && result.success) {
        downloadFile(result.csv, 'northpoint-invoice.csv')
      }
      if (result && !result.success && result.message) {
        setErrorMessage(result.message);
      }

    } catch (error) {
      console.log(error)
      setErrorMessage(`${error}`)
    }
    setIsLoading(false);
  };


  if (showCSVSelector) {
    return (
      <div>
        <h3 className={classes.title}>Select a FishBowl Invoice CSV File</h3>
        <Dropzone
          accept=".csv"
          onDrop={(acceptedFiles: any[]) => {
            if (acceptedFiles.length > 0) {
              const file = acceptedFiles[0];
              setSelectedFile(file);
              setErrorMessage('');
            }
          }}
        >
          {({getRootProps, getInputProps}) => (
            <section className={classes.uploadContainer}>
              <div className={classes.dropzone} {...getRootProps()}>
                <input {...getInputProps()} />
                <CloudUploadIcon fontSize="large" />
                <Typography className={classes.textAlignCenter}>Choose a file or drag here</Typography>
              </div>
              <div>
              {(selectedFile && selectedFile.name) &&
                <div>
                  <Chip
                    label={selectedFile.name ? selectedFile.name : ''}
                    className={classes.marginBottom}
                    onDelete={() => {
                      setSelectedFile(null);
                    }}
                  />
                </div>
              }
              </div>
              {!!errorMessage && <div style={{marginTop: 16, marginBottom: 16}}>
                <Alert severity="error">{errorMessage}</Alert>
              </div>}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading}
                  endIcon={<ChevronRightIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedFile) {
                      uploadCSVFile(selectedFile);
                    }
                    else {
                      setErrorMessage('Please select a csv file')
                    }
                  }}
                >{isLoading ? 'Loading...' : 'Proceed'}</Button>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }

  const renderField = (fieldName: string) => {
    let value = csvData[fieldName];
    let fieldType = 'text';
    let helperText: any = undefined;
    let inputProps = {};
    let numberFields = ['Quantity', 'Item Amount'];
    let dateFields = ['Ship Date', 'Invoice Date', 'Receipt Date'];
    let idFields = ['SLOC ID', 'Plant ID', 'Dealer Number'];

    if (numberFields.includes(fieldName)) {
      fieldType = 'number';
    }
    if (dateFields.includes(fieldName)) {
      fieldType = 'date';
      value = moment(csvData[fieldName]).format('YYYY-MM-DD');
    }
    if (idFields.includes(fieldName)) {
      helperText = (
        <div>
          {!!matchedDealerName && <div>{fieldName} is guessed from the dealer name included in the invoice ("{csvDealerName}") which is similar with "{matchedDealerName}" dealer found in the dealer cross reference list.</div>}
          <div><a className={classes.link} href='javascript:void(0)' onClick={() => setShowCrossReferenceTable(true)}>Select a dealer manually</a></div>
        </div>
      );
    }
    return (
      <TextField
          id={fieldName.replaceAll(' ', '').replaceAll('/', '-')}
          label={fieldName}
          variant="outlined"
          name={fieldName}
          value={value}
          type={fieldType}
          inputProps={inputProps}
          helperText={helperText}
          onChange={(e) => {
            let newValue = e.target.value;
            if (e.target.value && dateFields.includes(fieldName)) {
              fieldType = 'date';
              newValue = moment(e.target.value).format('MM/DD/YYYY');
            }
            let newCSVData = Object.assign({}, csvData);
            newCSVData[fieldName] = newValue;
            setCSVData(newCSVData);
          }}
        />
    );
  }

  return (
    <div style={{padding: 16}}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!isLoading) generateNorthPointCSV()
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {csvFields.map((fieldName, i) => (
              <FormControl key={`field-${i}`} fullWidth className={classes.fieldContainer}>
                {renderField(fieldName)}
              </FormControl>
            ))}
            </Grid>
        </Grid>
        {!!errorMessage && <div style={{marginTop: 16, marginBottom: 16}}>
          <Alert severity="error">{errorMessage}</Alert>
        </div>}
        <div className={classes.formButtons}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            endIcon={<ChevronRightIcon />}
            disabled={isLoading}
          >Generate NorthPoint Invoice CSV</Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              setSelectedFile(null);
              setShowCSVSelector(true);
              setCSVDealerName('');
              setCrossReferenceList([]);
              setErrorMessage('');
              setMatchedDealerName('');
            }}
            style={{margin: 16}}
          >
            Reset
          </Button>
        </div>
      </form>

      <Dialog
        open={showCrossReferenceTable}
        onClose={() => setShowCrossReferenceTable(false)} aria-labelledby="dealer-selection"
        maxWidth="xl"
      >
        <DialogTitle id="form-dialog-title">
          Dealer Cross Reference List
          <IconButton
            style={{float: 'right'}}
            onClick={() => setShowCrossReferenceTable(false)}
          ><CloseIcon /></IconButton>
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table className={classes.table} size="small" aria-label="cross reference table">
              <TableHead>
                {crossReferenceList.filter((row, i) => i === 0).map((row, i) => (
                  <TableRow key={`header-${i}`}>
                    {row.map((col, j) => (
                      <TableCell key={`header-${i}-${j}`}>{col}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {crossReferenceList.filter((row, i) => i !== 0).map((row, i) => (
                  <TableRow key={`content-${i}`} hover style={{cursor: 'pointer'}} >
                    {row.map((col, j) => (
                      <TableCell
                        key={`content-${i}-${j}`}
                        onClick={() => {
                          let dealerName = row[8];
                          let dealerNumber = row[7];
                          let sloc = row[3];
                          let plantId = row[5];
                          let confirmation = `Select this dealer? ${dealerName}`;
                          
                          if (window.confirm(confirmation)) {
                            let newCSVData = Object.assign({}, csvData);
                            newCSVData['SLOC ID'] = sloc;
                            newCSVData['Plant ID'] = plantId;
                            newCSVData['Dealer Number'] = dealerNumber;
                            setCSVDealerName(dealerName);
                            setCSVData(newCSVData);
                            setMatchedDealerName('');
                            setShowCrossReferenceTable(false);
                          }
                        }}
                        >{col}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}