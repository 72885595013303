import React, {
  useState,
  useEffect,
  useRef
} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink
} from "react-router-dom";

import { Form, Field } from "react-final-form";

import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  Snackbar,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Divider,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';

import formatNumber from 'format-number';
import bankersRounding from '../../../utils/bankersRounding';

import { makeStyles } from '@material-ui/core/styles';

import {
  BaseAPI,
} from '../../../data/BaseAPI';

import {
  includeRequiredParts,
  includeRecommendedParts,
  includeDraftParts,
  applyPartRulesToOrderLines,
  getApplicableDiscounts,
  computeTotal,
  computeTotalWithoutDiscounts,
  submitOrder,
  submitOrderDraft,
} from './boatFormUtils';

import {
  CustomerDataForm
} from './CustomerDataForm';
import {
  BoatConfigurator
} from './BoatConfigurator';
import {
  OrderSummary
} from './OrderSummary';

import {
  Boat,
  Part,
  PartGroup,
  GroupedPart,
  Dealer,
  PartVariant,
  PartRule,
  DealerDiscount,
  OrderDraft,
  ToastMessage,
} from '../../../types';

import {
  OrderLine,
  PartOrderLine,
  DiscountItem,
  FormValues
} from './OrderTypes';

import {
  useProfile
} from '../../../data/Profile';
import { getPartFromOrderLine, orderLinesDiff } from './parts/utils';
import { Toasts } from '../../collections/Toasts';


const useStyles = makeStyles({
  root: {
    padding: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  totalContainer: {
    padding: `20px 36px`,
    textAlign: 'right',
  },
  boatTitle: {
    marginBottom: 20,
  },
  noteContainer: {
    marginBottom: 20,
  },
  closeButton: {
    padding: 4,
  },
  listItem: {
    paddingRight: 112,
  },
  allowNewline: {
    whiteSpace: "pre-line",
  },
  negativeTopMargin: {
    marginTop: -19,
    paddingTop: 0,
  },
  flexDirectionRowReverse: {
    flexDirection: 'row-reverse',
  },
  footerGroup: {
    padding: 0,
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fieldLabel: {
    color: '#777',
  },
  metaContainer: {
    marginBottom: 10,
  },
  smallText: {
    fontSize: 12,
  },
  shippingAddressContainer: {
    marginBottom: 20,
  },
  bolder: {
    fontWeight: 'bold'
  },
});


export default function OrderBoatForm(props: {
  boat: Boat;
  className?: string;
  onCancel: () => void;
  onOrderCompleted: (orderId: number) => void;
  onOrderDraftCompleted: (orderDraftId: number) => void;
  showHiddenInfo?: boolean;  
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(undefined as undefined|FormValues);
  const [confirmSubmissionWithName, setConfirmSubmissionWithName] = useState(false);
  const [submitterName, setSubmitterName] = useState('');
  const [submitterNameError, setSubmitterNameError] = useState('');
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [orderDraftSubmitted, setOrderDraftSubmitted] = useState(false);
  const [newOrderId, setNewOrderId] = useState(undefined as number|undefined);
  const [newOrderDraftId, setNewOrderDraftId] = useState(undefined as number|undefined);
  const [orderDraft, setOrderDraft] = useState<OrderDraft|undefined>(undefined);

  const [showErrorSnackbar, setShowErrorSnackBar] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const [note, setNote] = useState('');
  const [total, setTotal] = useState(0);
  const [totalWithoutDiscount, setTotalWithoutDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [orderLines, setOrderLines] = useState([] as OrderLine[])
  const [applicableDiscounts, setApplicableDiscounts] = useState([] as DiscountItem[]);
  const [profile, profileLoading, updateProfile, updateProfilePicture] = useProfile();
  const [allDealers, setAllDealers] = useState([] as Dealer[]);
  const [dealerDiscounts, setDealerDiscounts] = useState([] as DealerDiscount[]);
  const [selectedDealer, setSelectedDealer] = useState(undefined as Dealer|undefined);
  const [toasts, setToasts] = React.useState<ToastMessage[]>([]);

  const [removedParts, setRemovedParts] = useState([] as Part[]);
  const previousOrderLines = useRef<OrderLine[]|null>(null);

  const loadOrderDraft = async (id: string, boat: Boat, dealers: Dealer[]) => {
    const api = new BaseAPI();
    try {
      const data = await api.get(`order-drafts/${id}/`);
      if ((data as any).id) {
        let orderDraft = data as OrderDraft;
        setOrderDraft(orderDraft);

        let o = [] as OrderLine[];
        o = includeDraftParts(o, boat, orderDraft.all_items);
        o = includeRequiredParts(o, boat);
        o = applyPartRulesToOrderLines(o, boat.part_rules);
        setOrderLines(o);

        console.log('[v2] draft selection:', o);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const loadAllDealers = async () => {
    let dealers = [] as Dealer[];
    const api = new BaseAPI();
    try {
      const data = await api.get('all-dealers/');
      if (data instanceof Array) {
        dealers = data as Dealer[];
        setAllDealers(data as Dealer[]);
        if (dealers.length == 1) setSelectedDealer(dealers[0]);
      }
    } catch (error) {
      console.error(error);
    }
    return dealers;
  };

  const loadDealerDiscounts = async () => {
    const api = new BaseAPI();
    try {
      const data = await api.get('dealer-discounts/');
      if (data instanceof Array) {
        const dealerDiscounts = data as DealerDiscount[];
        setDealerDiscounts(dealerDiscounts)
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    loadAllDealers();
    loadDealerDiscounts();

    let searchParams = new URLSearchParams(window.location.search);
    let draftId = searchParams.get('quote');
    if (draftId) {
      loadOrderDraft(draftId, props.boat, allDealers);
    }
    else {
      let o = [] as OrderLine[];
      o = includeRequiredParts(o, props.boat);
      o = includeRecommendedParts(o, props.boat);
      o = applyPartRulesToOrderLines(o, props.boat.part_rules);
      setOrderLines(o);

      console.log('[v2] initial selection:', o);
    }

  }, [props.boat]);

  useEffect(() => {
    if (allDealers.length === 0) return;
    if (!orderDraft?.dealer) return;

    allDealers.forEach((dealer) => {
      if (orderDraft?.dealer && orderDraft.dealer === dealer.id) setSelectedDealer(dealer);
    });

  }, [orderDraft?.dealer, allDealers.length]);

  useEffect(() => {
    if (orderLines.length === 0) return;
    if (dealerDiscounts.length === 0) return;

    setTotal(computeTotal(orderLines, selectedDealer, dealerDiscounts));
    setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));

    const applicableDiscounts = getApplicableDiscounts(orderLines, selectedDealer, dealerDiscounts);
    setApplicableDiscounts(applicableDiscounts);

    let totalDiscount = 0;
    applicableDiscounts.forEach(discount => totalDiscount += parseFloat(`${discount.total}`));
    setTotalDiscount(totalDiscount);

  }, [orderLines.length, dealerDiscounts.length]);


  useEffect(() => {
    setTotal(computeTotal(orderLines, selectedDealer, dealerDiscounts));
    setTotalWithoutDiscount(computeTotalWithoutDiscounts(orderLines));

    const applicableDiscounts = getApplicableDiscounts(orderLines, selectedDealer, dealerDiscounts);
    setApplicableDiscounts(applicableDiscounts);

    let totalDiscount = 0;
    applicableDiscounts.forEach(discount => totalDiscount += parseFloat(`${discount.total}`));
    setTotalDiscount(totalDiscount);

    console.log('updating totals...')
  }, [selectedDealer, dealerDiscounts.map(dealerDiscount => dealerDiscount.id).join(','), orderLines.map(orderLine => `${orderLine.id}`).join(',')]);

  const onValidate = (values: FormValues) => {
    const errors: any = {}

    if (!values.customer_number) errors.customer_number = "Required";
    if (!values.is_draft) {
      if (!values.shipping_name) errors.shipping_name = "Required";
      if (!values.address_line_1) errors.address_line_1 = "Required";
      if (!values.city) errors.city = "Required";
      if (!values.zip) errors.zip = "Required";
    }

    return errors;
  }

  const onSubmit = (values: FormValues) => {
    if (values.is_draft) {
      console.log('>>> draft', values);
      onSubmitAsDraft(values);

      return;
    }
    setConfirmSubmission(values);
    setNote(values.note);
  };

  const onSubmitConfirmed = async (submitterName: string) => {
    if (confirmSubmission) {
      let orderId: number|undefined = undefined;
      
      try {
        orderId = await submitOrder(confirmSubmission, orderLines, props.boat, submitterName, orderDraft ? orderDraft.id : null);
      } catch (e) {
        console.log('error:', e, (e as any).errorData);
        let errorMessage = "Error submitting your order. Please try again. Contact us if the issue persist."
        if ((e as any).errorData && (e as any).errorData.errors) {
          errorMessage = (e as any).errorData.errors.join(', ');
          errorMessage = "Error submitting your order: " + errorMessage;
        }
        setShowErrorMessage(errorMessage);
        setConfirmSubmission(undefined);
      }
      setIsSaving(false);
  
      if (orderId) {
        setOrderSubmitted(true);
        setNewOrderId(orderId);
      }
    }
  }

  const onSubmitAsDraft = async (values: FormValues) => {
    let draftId: number|undefined = undefined;
    setIsSaving(true);
    try {
      draftId = await submitOrderDraft(values, orderLines, props.boat, orderDraft ? orderDraft.id : null);
    } catch (e) {
      console.log('error:', e, (e as any).errorData);
      let errorMessage = "Error submitting your order draft. Please try again. Contact us if the issue persist."
      if ((e as any).errorData && (e as any).errorData.errors) {
        errorMessage = (e as any).errorData.errors.join(', ');
        errorMessage = "Error submitting your order draft: " + errorMessage;
      }
      setShowErrorMessage(errorMessage);
      setConfirmSubmission(undefined);
    }
    setIsSaving(false);

    if (draftId) {
      setOrderDraftSubmitted(true);
      setNewOrderDraftId(draftId);
    }
  }

  const notifyUserIfThereAreDeselectedParts = (newOrderLines: OrderLine[], oldOrderLines: OrderLine[]) => {
    const diff = orderLinesDiff(newOrderLines, oldOrderLines, props.boat);
    let shouldNotify = false;
    if (diff.added.length === 0) return;
    diff.removed.forEach(removedOrderLine => {
      const removedPart = getPartFromOrderLine(removedOrderLine, props.boat);
      if (removedPart) {
        removedPart.groups.forEach(removedPartGroup => {
          diff.added.forEach(addedOrderLine => {
            const addedPart = getPartFromOrderLine(addedOrderLine, props.boat);
            if (addedPart) {
              addedPart.groups.forEach(addedPartGroup => {
                if (addedPartGroup.id !== removedPartGroup.id) shouldNotify = true;
              });
              if (addedPart.groups.length === 0) shouldNotify = true;
            }
          });
        });
        if (removedPart.groups.length === 0) shouldNotify = true;
      }
    });

    console.log('diff', diff, shouldNotify)

    if (shouldNotify) {
      diff.removed.forEach(orderLine => {
        let newToasts = [...toasts]
        newToasts.push({
          title: `${orderLine.description} has been removed from selection.`,
          type: 'info'
        })
        setToasts(newToasts)
      })
    }
  }
  
  const selectedDealerAddress = (selectedDealer && selectedDealer.addresses.length > 0) ? selectedDealer.addresses[0] : undefined;
  const isEngineSelected = orderLines.filter(orderLine => orderLine.type === 'engine').length > 0;
  const isBoatSelected = orderLines.filter(orderLine => orderLine.type === 'boat').length > 0;
  const isBoatAndEngineSelected = isBoatSelected && isEngineSelected;

  let boatLogo = (<Typography variant="h2" className={classes.boatTitle}>{props.boat.name}</Typography>);
  if (props.boat.logo_full) {
    boatLogo = (<div><img src={props.boat.logo_full} width={415} height={66} alt={props.boat.name} title={props.boat.name} /></div>);
  }


  return (
    <div>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} style={{paddingLeft: 30}}>
            {boatLogo}
          </Grid>

          <Grid item xs={12}>
            <BoatConfigurator
              boat={props.boat}
              dealer={selectedDealer}
              orderLines={orderLines}
              setOrderLines={(newOrderLines) => {
                let o = applyPartRulesToOrderLines(newOrderLines, props.boat.part_rules);
                setOrderLines(o);
                console.log('>', o);

                if (previousOrderLines.current && (o.length == previousOrderLines.current.length)) {
                  const orderLineIds = o.map(orderLine => orderLine.id);
                  const removedOrderLines = previousOrderLines.current.filter(orderLine => !orderLineIds.includes(orderLine.id));
                  
                  const rList: Part[] = removedOrderLines.map(orderLine => getPartFromOrderLine(orderLine, props.boat)).filter(part => !!part) as Part[];
                  if (rList.length === 0) return;
            
                  setRemovedParts(rList);
                  console.log('removed', rList);
                }
                previousOrderLines.current = o;

                notifyUserIfThereAreDeselectedParts(newOrderLines, orderLines)
              }}
              showHiddenInfo={props.showHiddenInfo}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.totalContainer}>
              <Typography variant="body1">
                Total: <b>{formatNumber({prefix: 'US$'})(bankersRounding(totalWithoutDiscount, 2))}</b>
              </Typography>
            </div>
          </Grid>

          {(!selectedDealer && allDealers.length > 0) &&
          <Grid item xs={12}>
            <div className="pt-[20px] pr-[36px]">
              <Typography className="text-right text-gray-500" variant="body2">Please select a dealer to see any applicable discount</Typography>
            </div>
          </Grid>}

          {(applicableDiscounts.length > 0) &&
          <Grid item xs={12}>
            <div className="pt-[20px] pr-[36px]">
              {applicableDiscounts.map((discount, i) => (
                <Typography key={`discount-${i}`} className="text-right" variant="body2">Dealer Discount: {discount.name} — <b>{formatNumber({prefix: 'US$'})(parseFloat(`${discount.total}`))} discount</b></Typography>
              ))}
            </div>
          </Grid>}

          {(totalDiscount > 0) &&
          <Grid item xs={12}>
            <div className={classes.totalContainer}>
              <Typography variant="body1">
                Grand Total: <b>{formatNumber({prefix: 'US$'})(bankersRounding(total, 2))}</b>
              </Typography>
            </div>
          </Grid>}
        </Grid>
      </div>
    
      <CustomerDataForm
        key={`customer-data-form-${orderDraft ? orderDraft.id : '0'}`}
        onSubmit={onSubmit}
        onCancel={props.onCancel}
        onValidate={onValidate}
        onDealerSelected={(dealer, note) => {
          setSelectedDealer(dealer);
          setNote(note);
        }}
        saving={isSaving}
        dealers={allDealers}
        initialValues={{
          note: note,
          customer_number: selectedDealer ? selectedDealer.customer_number : '',
          order_type: 'stock',
          customer_name: '',
          shipping_name: selectedDealer ? selectedDealer.customer_name : '',
          address_line_1: selectedDealerAddress ? selectedDealerAddress.address_line_1 : '',
          address_line_2: selectedDealerAddress ? selectedDealerAddress.address_line_2 : '',
          city: selectedDealerAddress ? selectedDealerAddress.city : '',
          country: selectedDealerAddress ? selectedDealerAddress.country : 'United States',
          state: selectedDealerAddress ? selectedDealerAddress.state : '',
          zip: selectedDealerAddress ? selectedDealerAddress.zip : '',
          is_draft: orderDraft ? true : false,
        }}
        disabled={!isBoatAndEngineSelected}
        disabledMessage={isBoatAndEngineSelected ? '' : 'Please select an engine before proceeding.'}
      />

      <Snackbar
        key={'error-snackbar'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackBar(false)}
        onExited={() => setShowErrorSnackBar(false)}
        message="Error submitting your order. Please try again. Contact us if the issue persist."
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setShowErrorSnackBar(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />

      <Snackbar
        key={'error-snackbar-custom'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!showErrorMessage}
        onClose={() => setShowErrorMessage('')}
        onExited={() => setShowErrorMessage('')}
        message={showErrorMessage}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              onClick={() => setShowErrorMessage('')}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />

      <Dialog
        open={orderSubmitted}
        onClose={() => {
          if (newOrderId) props.onOrderCompleted(newOrderId);
        }}
        aria-labelledby="order-completed-title"
        aria-describedby="order-completed-description"
      >
        <DialogTitle id="order-completed-title">{"Order Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="order-completed-description">
            Your order has been successfully submitted!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              if (newOrderId) props.onOrderCompleted(newOrderId);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={orderDraftSubmitted}
        onClose={() => {
          if (newOrderDraftId) props.onOrderDraftCompleted(newOrderDraftId);
        }}
        aria-labelledby="draft-completed-title"
        aria-describedby="draft-completed-description"
      >
        <DialogTitle id="draft-completed-title">{"Quote Saved"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="draft-completed-description">
            Your quote has been successfully saved!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              if (newOrderDraftId) props.onOrderDraftCompleted(newOrderDraftId);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={!!confirmSubmission}
        onClose={() => setConfirmSubmission(undefined)}
        scroll='paper'
        aria-labelledby="confirm-order"
      >
        <DialogTitle id="confirm-order">Confirm Order</DialogTitle>
        <DialogContent dividers>
          <Alert severity="info">Please confirm the order details below, initial, and submit. Once submitted, any changes that need to be made must go through Sea Fox’s Sales Department.</Alert>
          <div className="mt-4">
          {confirmSubmission &&
          <OrderSummary
            formValues={confirmSubmission}
            boat={props.boat}
            orderLines={orderLines}
            totalPrice={formatNumber({prefix: 'US$'})(bankersRounding(total, 2))}
            totalPriceWithoutDiscount={formatNumber({prefix: 'US$'})(bankersRounding(totalWithoutDiscount, 2))}
            discounts={applicableDiscounts}
          />}
          </div>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-between'}}>
          <span>
          <Button
            onClick={() => {
              setConfirmSubmissionWithName(true);
              setSubmitterNameError('');
            }}
            variant="contained"
            color="primary"
            startIcon={<SendIcon />}
            disabled={isSaving || !total}
          >
            Submit Order ({formatNumber({prefix: 'US$'})(total)})
          </Button>
          {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
          </span>
          <Button onClick={() => setConfirmSubmission(undefined)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmSubmissionWithName}
        onClose={() => {
          setConfirmSubmissionWithName(false)
        }}
        aria-labelledby="order-confirmation-enter-submitter-name-title"
        aria-describedby="order-confirmation-enter-submitter-name-description"
      >
        <DialogTitle id="order-confirmation-enter-submitter-name-title">Enter Your Name To Confirm Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="order-confirmation-enter-submitter-name-description">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <TextField
                id="submitter-name"
                label="Your Name"
                variant="outlined"
                name="submitter-name"
                value={submitterName}
                onChange={(e) => setSubmitterName(e.target.value)}
                error={submitterNameError ? true: false}
                helperText={submitterNameError}
              />
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-between'}}>
          <span>
          <Button
            onClick={() => {
              if (!submitterName) setSubmitterNameError('Please enter your initial!');
              else {
                setConfirmSubmissionWithName(false);
                onSubmitConfirmed(submitterName);
              }
            }}
            variant="contained"
            color="primary"
            disabled={isSaving || !total}
          >
            Submit
          </Button>
          {isSaving && <CircularProgress size={20} style={{marginLeft: 10}} />}
          </span>
          <Button onClick={() => setConfirmSubmissionWithName(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Toasts
        toasts={toasts}
        onSetToasts={(toasts) => setToasts(toasts)}
      />
    </div>
  );
}