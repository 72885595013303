import React, {
useState,
useEffect
} from 'react';

import moment from 'moment';

import { Form, Field } from "react-final-form";
import { FORM_ERROR } from 'final-form'

import {
BrowserRouter as Router,
Switch,
Route,
Redirect,
useRouteMatch,
useParams,
Link as RouterLink
} from "react-router-dom";
import formatNumber from 'format-number';

import { makeStyles } from '@material-ui/core/styles';
import {
Typography,
Button,
Checkbox,
Radio,
RadioGroup,
FormControlLabel,
ListSubheader,
CircularProgress,
Grid,
FormControl,
TextField,
InputLabel,
Select,
MenuItem,
Collapse,
Snackbar,
IconButton,
Dialog,
DialogContent,
DialogTitle,
DialogActions,
DialogContentText,
Table,
TableBody,
TableCell,
TableContainer,
TableHead,
TableRow,
Paper,
Card,
CardActions,
CardContent,
Chip,
InputAdornment,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import {
APIError,
BaseAPI,
} from '../../../data/BaseAPI';

import {
BoatEditor,
BoatEditorPartGroup,
BoatEditorPart,
BoatEditorPartVariant,
BoatEditorPartOption,
PartPicture,
} from '../../../types';

import localization from '../../../utils/localizations';
import DateTimeView from '../../singles/DateTimeView';
import PartPictures from '../../collections/PartPictures';

const useStyles = makeStyles({
root: {

},
fieldContainer: {
    marginBottom: 20,
    '& .MuiInputBase-root ': {
    backgroundColor: '#fff'
    }
},
partVariantContainer: {
    borderTop: 'solid 1px #ddd',
    marginRight: -12,
    marginLeft: -12,
    padding: 12,
    '&:hover': {
    backgroundColor: '#f9f9f9'
    }
},
partVariantFieldsContainer: {

},
partAdvancedContainer: {
  marginTop: 12,
  marginBottom: 24,
},
linkedPartsContainer: {
  border: 'solid 1px rgba(0, 0, 0, 0.23)',
  padding: 6,
  borderRadius: 4,
  backgroundColor: '#fff',
}
});

  
export default function PartVariantEditorElement(props: {
  group: BoatEditorPartGroup;
  boat: BoatEditor;
  partVariant: BoatEditorPartVariant;
  part: BoatEditorPart;
  parts: BoatEditorPart[];
  linkedParts: BoatEditorPart[];
  partVariants: BoatEditorPartVariant[];
  disabled?: boolean;
  showAdvancedOptions?: boolean;
  onPartVariantsUpdated: (part: BoatEditorPartVariant[]) => void;
  onPartVariantRemoved: (part: BoatEditorPartVariant) => void;
  onLinkedPartUpdated: (part: BoatEditorPart) => void;
  onLinkedPartRemoved: (part: BoatEditorPart) => void;
  onAddPartToVariant?: (variant: BoatEditorPartVariant) => void;
  onPartPictureRemoved: (picture: PartPicture) => void;
}) {
  const classes = useStyles();
  const [partVariant, setPartVariant] = useState<BoatEditorPartVariant>(props.partVariant);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(props.showAdvancedOptions ? true : false);

  const [includedPartPictures, setIncludedPartPictures] = useState<PartPicture[]>([]);
  const [removedPartPictures, setRemovedPartPictures] = useState<PartPicture[]>([]);

  const loadPartPicture = async (partPictureId: string) => {
    const api = new BaseAPI();
    
    let url = `boats/editor/part-pictures/${partPictureId}/`;
    try {
      const data: any = await api.get(url);
      if (data.id) {
        return data as PartPicture;
      }
    } catch (error) {
      console.error(error);
    }

    return undefined;
  };

  const loadPartPictures = async (ids: string[]) => {
    let picturePromises = ids.map(pid => loadPartPicture(pid));

    let pictures = await Promise.all(picturePromises);
    return pictures.filter(p => p !== undefined) as PartPicture[];
  }

  const loadAllPartPictures = async (partVariant: BoatEditorPartVariant) => {
    let boatPartPictures = await loadPartPictures(partVariant.pictures);
    setIncludedPartPictures(boatPartPictures);
  }

  useEffect(() => {
    setPartVariant(props.partVariant);
    loadAllPartPictures(props.partVariant);
  }, [props.partVariant]);

  const group = props.group;
  const boat = props.boat;
  const parts = props.parts;
  const part = props.part;
  const partVariants = props.partVariants;

  return (
    <div
      className={classes.partVariantContainer}
    >
      <div className={classes.partVariantFieldsContainer}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth className={classes.fieldContainer}>
                  <TextField
                    id="part_number"
                    label={'Variant Part Number'}
                    variant="outlined"
                    name="part_number"
                    value={partVariant.part_number}
                    size="small"
                    onChange={(e) => {
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      p.part_number = e.target.value;
                      setPartVariant(p);
                    }}
                    onBlur={() => {
                      props.onPartVariantsUpdated([partVariant]);
                    }}
                    helperText=""
                  />
                </FormControl>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth className={classes.fieldContainer}>
                  <TextField
                    id="name"
                    label={'Variant Part Name'}
                    variant="outlined"
                    name="name"
                    value={partVariant.name}
                    required
                    size="small"
                    onChange={(e) => {
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      p.name = e.target.value;
                      setPartVariant(p);
                    }}
                    onBlur={() => {
                      props.onPartVariantsUpdated([partVariant]);
                    }}
                    helperText=""
                  />
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="csv_description"
                label={'Variant CSV Description'}
                variant="outlined"
                name="csv_description"
                value={partVariant.csv_description}
                required
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.csv_description = e.target.value;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="description"
                label={'Variant Description'}
                variant="outlined"
                name="description"
                value={partVariant.description}
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.description = e.target.value;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
              />
            </FormControl>
            <Grid container>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={partVariant.active}
                    onChange={(e) => {
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      p.active = e.target.checked;
                      setPartVariant(p);
                      setTimeout(() => {
                        props.onPartVariantsUpdated([p]);
                      }, 0);
                    }}
                    name="active"
                  />}
                  label="Active"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={partVariant.recommended}
                    onChange={(e) => {
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      p.recommended = e.target.checked;
                      setPartVariant(p);
                      setTimeout(() => {
                        props.onPartVariantsUpdated([p]);
                      }, 0);
                    }}
                    name="is_recommended"
                  />}
                  label="Recommended"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={partVariant.admin_only}
                    onChange={(e) => {
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      p.admin_only = e.target.checked;
                      setPartVariant(p);
                      setTimeout(() => {
                        props.onPartVariantsUpdated([p]);
                      }, 0);
                    }}
                    name="admin_only"
                  />}
                  label="Admin Only"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                  <Checkbox
                    checked={partVariant.boats.includes(boat.id)}
                    onChange={(e) => {
                      let thisBoatOnly = e.target.checked;
                      let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                      if (thisBoatOnly) {
                        if (!partVariant.boats.includes(boat.id)) p.boats.push(boat.id);
                      }
                      else {
                        p.boats = partVariant.boats.filter(boatId => boatId !== boat.id);
                      }
                      setPartVariant(p);
                      setTimeout(() => {
                        props.onPartVariantsUpdated([p]);
                      }, 0);
                    }}
                    name="this_boat_only"
                  />}
                  label="Only appear on this boat"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price"
                label={'Price'}
                variant="outlined"
                name="price"
                value={partVariant.price}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.price = e.target.value;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">Dealer</InputAdornment>,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price_msrp"
                label={'MSRP Price'}
                variant="outlined"
                name="price_msrp"
                value={partVariant.price_msrp}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.price_msrp = e.target.value;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">International</InputAdornment>,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="price_msrp_deal"
                label={'MSRP Deal Price'}
                variant="outlined"
                name="price_msrp_deal"
                value={partVariant.price_msrp_deal}
                type="number"
                size="small"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.price_msrp_deal = e.target.value;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">US</InputAdornment>,
                }}
              />
            </FormControl>
            <div>
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >{showAdvancedOptions ? 'Less Options...' : 'Advanced Options...'}</Button>
              {(part.variants_ordering === 'custom') &&
              <IconButton
                size="small"
                style={{marginLeft: 12}}
                onClick={() => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.order = partVariant.order - 1;
                  setPartVariant(p);
                  props.onPartVariantsUpdated([p]);
                }}
              >
                <ArrowUpwardIcon />
              </IconButton>}
              {(part.variants_ordering === 'custom') &&
              <IconButton
                size="small"
                onClick={() => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.order = partVariant.order + 1;
                  setPartVariant(p);
                  props.onPartVariantsUpdated([p]);
                }}
              >
                <ArrowDownwardIcon />
              </IconButton>}
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{float: 'right'}}
                onClick={() => {
                  if (window.confirm(`remove ${partVariant.part_number} ${partVariant.name} ?`)) props.onPartVariantRemoved(partVariant);
                }}>Remove</Button>
            </div>
          </Grid>
        </Grid>

      <Collapse in={showAdvancedOptions}>
        <div className={classes.partAdvancedContainer}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            <FormControl fullWidth className={classes.fieldContainer}>
              <TextField
                id="quantity"
                label={'Quantity'}
                variant="outlined"
                name="quantity"
                value={partVariant.quantity}
                type="number"
                onChange={(e) => {
                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.quantity = e.target.value ? parseInt(e.target.value) : 1;
                  setPartVariant(p);
                }}
                onBlur={() => {
                  props.onPartVariantsUpdated([partVariant]);
                }}
                helperText=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">x</InputAdornment>,
                }}
              />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth className={classes.fieldContainer}>
                <TextField
                  id="order"
                  label={'Custom Order Key'}
                  variant="outlined"
                  name="order"
                  value={partVariant.order}
                  type="number"
                  onChange={(e) => {
                    let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                    p.order = e.target.value ? parseInt(e.target.value) : 1;
                    setPartVariant(p);
                  }}
                  onBlur={() => {
                    props.onPartVariantsUpdated([partVariant]);
                  }}
                  helperText=""
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.linkedPartsContainer}>
                <div style={{color: '#555', fontSize: 13, marginBottom: 5}}>Only show this variant if the following part is selected</div>
                {partVariant.parts.map((pid, i) => {
                  const parts = props.linkedParts.filter(p => {
                    if (p.id && p.id === pid) return true;
                    if (p.local_id && p.local_id === pid) return true;
                  });
                  let label = (parts.length > 0) ? `${parts[0].part_number} — ${parts[0].description}` : `part #${pid}`
                  return (
                    <Chip
                      key={`variant_part_${pid}`}
                      label={label}
                      clickable
                      onDelete={() => {
                        if (!window.confirm('remove this item?')) return;
                        let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                        p.parts = partVariant.parts.filter(id => id !== pid);
                        setPartVariant(p);
                        props.onPartVariantsUpdated([partVariant]);
                      }}
                      style={{marginTop: 6, marginRight: 6}}
                    />
                  );
                })}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    if (props.onAddPartToVariant) props.onAddPartToVariant(partVariant);
                  }}
                >Add Part</Button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PartPictures
                title="Pictures"
                pictures={includedPartPictures}
                onAdded={(picture: PartPicture) => {
                  let newIncludedPartPictures = [...includedPartPictures];
                  newIncludedPartPictures.push(picture);
                  setIncludedPartPictures(newIncludedPartPictures);

                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.pictures.push(picture.id);
                  setPartVariant(p);
                  props.onPartVariantsUpdated([p]);
                }}
                onRemoved={(picture: PartPicture) => {
                  console.log('removed', picture);
                  let newIncludedPartPictures = includedPartPictures.filter(p => p.id !== picture.id);
                  setIncludedPartPictures(newIncludedPartPictures);

                  let newRemovedPartPictuces = [...removedPartPictures];
                  newRemovedPartPictuces.push(picture);
                  setRemovedPartPictures(newRemovedPartPictuces);

                  let p = Object.assign({}, partVariant) as BoatEditorPartVariant;
                  p.pictures = p.pictures.filter(pid => pid !== picture.id);
                  
                  if (!p.removed_pictures) p.removed_pictures = [];
                  p.removed_pictures.push(picture.id);
                  setPartVariant(p);
                  props.onPartVariantsUpdated([p]);
                  props.onPartPictureRemoved(picture);
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Collapse>

      </div>
    </div>);
}